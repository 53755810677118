import React from "react"
import {Home} from "../components/Pages/Home"
import {FrontPage} from "../components/Pages/FrontPage"

// Demo page => http://preview.themeforest.net/item/Wonder Digital Media AS-corporate-finance-react-template/full_screen_preview/25803862?_ga=2.232626143.1154004516.1611917463-123822607.1598417091

// All Css File Import
import '../assets/css/bootstrap.min.css'
import '../assets/vendors/linericon/style.css'
import '../assets/css/font-awesome.min.css'
import '../assets/vendors/owl-carousel/assets/owl.carousel.min.css'
import '../assets/vendors/lightbox/simpleLightbox.css'
import '../assets/vendors/nice-select/css/nice-select.css'
import '../assets/vendors/animate-css/animate.css'
import '../assets/vendors/popup/magnific-popup.css'
import '../assets/vendors/stroke-icon/style.css'
import '../assets/vendors/flaticon/flaticon.css'
import '../assets/css/style.css'
import '../assets/css/responsive.css'


const IndexPage = () => {
  return (
    <main>
        <FrontPage/>
    </main>
  )
}

export default IndexPage
