import React, {Component} from 'react';

class TestiItem extends Component{
    render(){
        var {Description, Name, Country, Image} = this.props;
        return(
            <div className="item">
                <div className="testi_item">
                    <p>{Description}</p>
                    <div className="media">
                        <div className="d-flex">
                            <img className="rounded-circle" src={require("../../assets/img/testimonials/" + Image)} alt="" />
                        </div>
                        <div className="media-body">
                            <h4>{Name}</h4>
                            <p>{Country}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default TestiItem;