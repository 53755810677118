import React, {Component} from 'react';
import Title from './Title';


class HomeContact extends Component{
    render(){
        var {Address, Number, Email, CheckIn, CheckOut, Image} = this.props;
        return(
            <div className="home_contact_form p_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <Title Title="DROP A MESSAGE" />
                            <form className="row contact_us_form" action="contact_process.php" method="post" id="contactForm">
                                <div className="form-group col-lg-12">
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Name" />
                                </div>
                                <div className="form-group col-lg-12">
                                    <input type="email" className="form-control" id="email" name="email" placeholder="Email address" />
                                </div>
                                <div className="form-group col-md-12">
                                    <input type="text" className="form-control" id="subject" name="subject" placeholder="Subject" />
                                </div>
                                <div className="form-group col-md-12">
                                    <textarea className="form-control" name="message" id="message" rows="1" placeholder="Wrtie message"></textarea>
                                </div>
                                <div className="form-group col-md-12">
                                    <button type="submit" value="submit" className="btn submit_btn form-control">Submit Now <strong className="icon"><i className="fa fa-paper-plane" aria-hidden="true"></i></strong></button>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6">
                            <div className="get_in_touch">
                                <Title Title="GET IN TOUCH" />
                                <div className="media">
                                    <div className="d-flex"> 
                                        <img src={require("../../assets/img/" + Image)} alt="" />
                                    </div>
                                    <div className="media-body">
                                        <div className="contact_details">
                                            <div className="media">
                                                <div className="d-flex">
                                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                </div>
                                                <div className="media-body">
                                                    <p>{Address}</p> 
                                                </div>
                                            </div>
                                            <div className="media">
                                                <div className="d-flex">
                                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                                </div>
                                                <div className="media-body">
                                                    <a href={"tel:" + Number}><span>Call Us: </span>{Number}</a>
                                                    <a href={"mailto:" + Email}><span>Email : </span> {Email}</a>
                                                </div>
                                            </div>
                                            <div className="media">
                                                <div className="d-flex">
                                                    <i className="fa fa-clock-o" aria-hidden="true"></i>
                                                </div>
                                                <div className="media-body">
                                                    <h5>Check In : <span>{CheckIn}</span></h5>
                                                    <h5>Check Out : <span>{CheckOut}</span></h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default HomeContact;