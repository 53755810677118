import React, {Component} from 'react';


class BusinessList extends Component{
    render(){
        return(
        <li><a href=".#"><i className="lnr lnr-arrow-right"></i>{this.props.ListText}</a></li>
        );
    }
}
export default BusinessList;