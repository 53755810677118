import React, {Component} from 'react';
import BusinessList from '../Elements/BusinessList';


class CreateBusiness extends Component{
    render(){
        var {Image, Title, Description} = this.props;
        return(
            <section className="who_creat_business"> 
                <div className="container">
                    <div className="who_creat_inner row align-items-center d-flex">
                        <div className="col-lg-5">
                            <img className="img-fluid" src={require("../../../src/assets/img/" + Image)} alt="" />
                        </div>
                        <div className="col-lg-7">
                            <div className="who_creat_text">
                                <h2>{Title}</h2>
                                <p>{Description}</p>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <ul className="list">
                                            <BusinessList ListText="Audit & Assurance" />
                                            <BusinessList ListText="Business Services" />
                                            <BusinessList ListText="IT Control Solutions" />
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <ul className="list">
                                        <BusinessList ListText="Neque poro quisquam" />
                                        <BusinessList ListText="Business Services" />
                                        <BusinessList ListText="IT Control Solutions" />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default CreateBusiness;
