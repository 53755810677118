import React, {Component} from 'react';

class HomeSlider extends Component{
    render(){
        return(
            <section className="home_slider_area">
                <div className="home_main_slider owl-carousel">
                    <div className="item">
                        <img src={require('../../assets/img/home-slider/slider-1.jpg')} alt="" />
                        <div className="slider_content">
                            <div className="container">
                            <h2>Spend your Dream Holidays With us</h2>
                            <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos quit.
                            </p>
                            <a className="main_btn" href=".#">Read More <strong className="icon"><i className="fa fa-repeat" aria-hidden="true"></i></strong></a>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <img src={require('../../assets/img/home-slider/slider-2.jpg')} alt="" />
                        <div className="slider_content">
                            <div className="container">
                            <h2>Spend your Dream Holidays With us</h2>
                            <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos quit.
                            </p>
                            <a className="main_btn" href=".#">Read More <strong className="icon"><i className="fa fa-repeat" aria-hidden="true"></i></strong></a>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <img src={require('../../assets/img/home-slider/slider-3.jpg')} alt="" />
                        <div className="slider_content">
                            <div className="container">
                            <h2>Spend your Dream Holidays With us</h2>
                            <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos quit.
                            </p>
                            <a className="main_btn" href=".#">Read More <strong className="icon"><i className="fa fa-repeat" aria-hidden="true"></i></strong></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default HomeSlider;