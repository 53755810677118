import React, {Component} from 'react';
import TeamItem from './TeamItem';


class OurTeam extends Component{
    render(){
        var {Title, Description} = this.props;
        return(
            <section className="our_team_area p_100">
                <div className="container">
                    <div className="row team_inner">
                        <div className="col-lg-3">
                            <div className="team_text">
                                <h3>{Title}</h3>
                                <p>{Description}</p>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row team_list">
                                <TeamItem Image="team-h-1.jpg" Name="Andy" Position="Corffenshtauer" Designation="Developer" />
                                <TeamItem Image="team-h-2.jpg" Name="John" Position="Corffenshtauer" Designation="Developer" />
                                <TeamItem Image="team-h-3.jpg" Name="Rocky Ahmed" Position="Director" Designation="Lead Developer" />
                                <TeamItem Image="team-h-4.jpg" Name="Shima" Position="Partner" Designation="UI Designer" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default OurTeam;