import React, {Component} from 'react'; 
import SmallServiceItem from '../Elements/SmallServiceItem';


class HomeSmallService extends Component{
    render(){
        return(
            <section className="home_service">
                <div className="container">
                    <div className="row m0">
                        <SmallServiceItem Title="FINANCIAL SERVICE" Description="Duis aute irure dolor in reprehen-derit in volup tate velit esse cillum dolore eufug fiat nulla" />
                        <SmallServiceItem Title="BEST CONSULTANCY" Description="Duis aute irure dolor in repre-henderit in volup tate velit esse cillum dolore eufug fiat nulla" />
                        <SmallServiceItem Title="BUSINESS ANALYSIS" Description="Duis aute irure dolor in re-prehenderit in volup tate velit esse cillum dolore eufug fiat nulla" />
                    </div>
                </div>
            </section>
        );
    }
}
export default HomeSmallService;