import React, {Component} from 'react';


class LatestBlogItem extends Component{
    render(){
        var {Image, Title, Description, Author, Comment} = this.props;
        return(
            <div className="col-lg-4 col-md-6">
                <div className="hl_post_item">
                    <a href="single-blog.html" className="post_img">
                        <img src={require("../../assets/img/blog/latest-post/" + Image)} alt="" />
                    </a>
                    <div className="post_text">
                        <a href="single-blog.html"><h4>{Title}</h4></a>
                        <p>{Description}</p>
                        <ul className="list">
                            <li><a href=".#"><i className="fa fa-user"></i>{Author}</a></li>
                            <li><a href=".#"><i className="fa fa-comment"></i>{Comment}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default LatestBlogItem;