import React, {Component} from 'react';
import LatestBlogItem from './LatestBlogItem';
import Title from './Title';

class HomeBlog extends Component{
    render(){
        return(
            <section className="h_l_post_area p_100">
                <div className="container">
                    <Title Title="LATEST POSTS" />
                    <div className="row hl_post_inner">
                        <LatestBlogItem 
                            Image="l-post-1.jpg" 
                            Title="Accusantium Dolore Mque Laud Antium Dolore Mque"
                            Description="Totam rem aperiam, eaque ipsa quae ab illo invent ore veritatis et quasi architecto beatae vitae dict eaque ipsa quae ab.Teritatis et quasi architecto. Sed ut perspi ciatis unde omnis iste natus error sit."
                            Author="Rocky" 
                            Comment="comments:04"
                        />
                        <LatestBlogItem 
                            Image="l-post-2.jpg" 
                            Title="Accusantium Dolore Mque Laud Antium Dolore Mque"
                            Description="Totam rem aperiam, eaque ipsa quae ab illo invent ore veritatis et quasi architecto beatae vitae dict eaque ipsa quae ab.Teritatis et quasi architecto. Sed ut perspi ciatis unde omnis iste natus error sit."
                            Author="Rocky" 
                            Comment="comments:03"
                        />
                        <LatestBlogItem 
                            Image="l-post-3.jpg" 
                            Title="Accusantium Dolore Mque Laud Antium Dolore Mque"
                            Description="Totam rem aperiam, eaque ipsa quae ab illo invent ore veritatis et quasi architecto beatae vitae dict eaque ipsa quae ab.Teritatis et quasi architecto. Sed ut perspi ciatis unde omnis iste natus error sit."
                            Author="Rocky" 
                            Comment="comments:06"
                        />
                    </div>
                </div>
            </section>
        );
    }
}
export default HomeBlog;