import React, {Component} from 'react';


class TeamItem extends Component{
    render(){
        var {Image, Name, Position, Designation} = this.props;
        return(
            <div className="col-lg-6 col-md-6">
                <div className="team_item">
                    <div className="media">
                        <div className="d-flex">
                            <img className="rounded-circle" src={require("../../assets/img/team/" + Image)} alt="" />
                        </div>
                        <div className="media-body">
                            <h4>{Name}</h4>
                            <h5>{Position}</h5>
                            <p>{Designation}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default TeamItem;