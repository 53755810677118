import React, {Component} from "react";

class SmallServiceItem extends Component{
    render(){
        var {Title, Description} = this.props;
        return(
            <div className="h_service_item">
                <h4>{Title}</h4>
                <p>{Description}</p>
            </div>
        );
    }
}
export default SmallServiceItem;