import React, {Component} from 'react';
import Title from './Title';
import TestiItem from './TestiItem';



class TestimonialsSlider extends Component{
    
    render(){
        
        return(
            <section className="testimonials_area p_100">
                <div className="container">
                    <Title Title="WHAT OUR CUSTOMER SAYS" />
                    <div className="testi_slider owl-carousel">
                        <TestiItem 
                            Description="Totam rem aperiam, eaque ipsa quae ab illo invent ore veritatis et quasi architecto beatae vitae dict eaque ipsa quae ab.Teritatis et quasi architecto. Sed ut perspi ciatis unde omnis iste natus error sit volu ptatem accusantium dolore mque." 
                            Name="Rocky Ahammed"
                            Country="California"
                            Image="testi-1.jpg"
                        />
                        <TestiItem 
                            Description="Totam rem aperiam, eaque ipsa quae ab illo invent ore veritatis et quasi architecto beatae vitae dict eaque ipsa quae ab.Teritatis et quasi architecto. Sed ut perspi ciatis unde omnis iste natus error sit volu ptatem accusantium dolore mque." 
                            Name="Allien Jhon"
                            Country="California"
                            Image="testi-2.jpg"
                        />
                        <TestiItem 
                            Description="Totam rem aperiam, eaque ipsa quae ab illo invent ore veritatis et quasi architecto beatae vitae dict eaque ipsa quae ab.Teritatis et quasi architecto. Sed ut perspi ciatis unde omnis iste natus error sit volu ptatem accusantium dolore mque." 
                            Name="Allien Jhon"
                            Country="California"
                            Image="testi-1.jpg"
                        />
                    </div>
                </div>
            </section>
        );
    }
}
export default TestimonialsSlider;